import React, {useEffect, useRef, useState} from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import LOCATION from '../../../../constants/Location';
import { formatRecord } from "../../../../utility";
import {useAuth} from "../../../../services/Auth";

function HotelFilters({ setValue, register, errors, watch, unregister, namePrefix, filters: data, holidayType }) {
    const requestStore = useRef({})
    const { current: requests } = requestStore

    const auth = useAuth()

    const { t } = useTranslation();
    const [options, setOptions] = useState([]);

    const filters = watch(`${namePrefix ? namePrefix + '.' :  ''}filters`, data ?? []);

    const toggleFilter = (filter) => {
        const tempFilters = filters.map(f => f.id).includes(filter.id) ? filters.filter(f => f.id !== filter.id) : [...filters, filter];
        setValue(`${namePrefix ? namePrefix + '.' :  ''}filters`, tempFilters);
    }

    const getFilters = (search) => {

        requests.filters && requests?.filters?.abort && requests.filters.abort()

        return new Promise((resolve, reject) => {
            requests.filters = auth.getRequest(LOCATION.HOTEL_FILTERS.API.path, { search, holiday_type_id: holidayType?.id })

            requests.filters.then(response => {
                resolve(response.data?.data?.map(formatRecord))
            })
                .catch(error => reject(error))
        })
    }

    useEffect(() => {
        getFilters().then(setOptions);
    }, [holidayType]);

    return (
        <>
            {/*<p>{t('pages.trips.form.labels.hotels_filters.description')}</p>*/}
            {
                options.length > 0 ? (
                    <Row>
                        <Col md={6}>
                            <p>{t('pages.trips.form.labels.hotels_filters.hotels')}</p>
                            {options?.filter((option) => !option.is_food)?.map((option, index) => (
                                <Form.Check
                                    key={index}
                                    type="checkbox"
                                    id={`filter-${index}`}
                                    label={option.label}
                                    checked={filters.map(f => f.value).includes(option.value)}
                                    onChange={() => toggleFilter(option)}
                                />
                            ))}
                        </Col>
                        <Col md={6}>
                            <p>{t('pages.trips.form.labels.hotels_filters.food')}</p>
                            {options?.filter((option) => option.is_food)?.map((option, index) => (
                                <Form.Check
                                    key={index}
                                    type="checkbox"
                                    id={`filter-${index}`}
                                    label={option.label}
                                    checked={filters.map(f => f.value).includes(option.value)}
                                    onChange={() => toggleFilter(option)}
                                />
                            ))}
                        </Col>
                    </Row>
                )
                :
                null
            }
        </>
    );
}

export default HotelFilters;