import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { useTranslation } from 'react-i18next'

function TravelMaxFeatures () {
  const { t } = useTranslation()

  return (
      <>
          <h5 className="heading-text my-4 head">
              The Best Value Travel Subscription!
          </h5>
          <ul className="list-group list-group-flush benefits-list mt-3">
              <li className="list-group-item d-flex ps-0">
                  <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>
                  <p className="mb-2">Receive personalised deal alerts ensuring you never miss out on the best DIY
                      prices.</p>
              </li>
              <li className="list-group-item d-flex ps-0">
                  <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>
                  <p className="mb-2">Fantastic flight and hotel prices are refreshed every week to keep you ahead of
                      the
                      crowd.</p>
              </li>
              <li className="list-group-item d-flex ps-0">
                  <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>
                  <p className="mb-2">Control your deal alerts with ease, turning them on or off anytime to fit your
                      travel
                      plans.</p>
              </li>
              <li className="list-group-item d-flex ps-0">
                  <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>
                  <p className="mb-2">Save multiple deals to easily compare options and secure the best one for you.</p>
              </li>
              <li className="list-group-item d-flex ps-0">
                  <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>
                  <p className="mb-2">Stay informed when prices change, so you can book at the perfect time.</p>
              </li>
              <li className="list-group-item d-flex ps-0">
                  <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>
                  <p className="mb-2">Unlock access to incredible trips under £200, designed just for you.</p>
              </li>
              <li className="list-group-item d-flex ps-0">
                  <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>
                  <p className="mb-2">Discover custom weekend getaways, perfect for a spontaneous escape.</p>
              </li>
              <li className="list-group-item d-flex ps-0">
                  <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>
                  <p className="mb-2">Access DIY beach deals in the perfect locations.</p>
              </li>
              <li className="list-group-item d-flex ps-0">
                  <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>
                  <p className="mb-2">Bargain Long-haul adventures make your dream destinations affordable.</p>
              </li>
              <li className="list-group-item d-flex ps-0">
                  <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>
                  <p className="mb-2">Smooth and stress-free journeys with our expert support.</p>
              </li>
              <li className="list-group-item d-flex ps-0">
                  <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>
                  <p className="mb-2">Enjoy access to exclusive discounts and giveaways.</p>
              </li>
          </ul>
      </>
  )
}

export default TravelMaxFeatures