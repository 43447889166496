import { Breadcrumb, Button, Card, Col, Row } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import { useAuth } from '@/services/Auth'
import { useTranslation } from 'react-i18next'
import CityCard from '../../components/CityCard'
import HotelCard from '../../components/HotelCard'
import LoadingCover from '@/shared/components/LoadingCover'
import FlightCard from '../../components/FlightCard'
import LOCATION from '@/constants/Location'
import LoadingButton from '@/shared/components/LoadingButton'
import OnPageNotification from '@/shared/components/OnPageNotification'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeftLong, faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import AppModal from '@/shared/components/AppModal'
import { Link, useHistory } from 'react-router-dom'
import moment from 'moment'

function TripOverview ({ deal, dealWeekId, tripExists }) {
  let history = useHistory()

  const auth = useAuth()
  const { t } = useTranslation()

  const [tripSaved, setTripSaved] = useState(false)
  const [dealRemoved, setDealRemoved] = useState(false)
  const [savingTrip, setSavingTrip] = useState(false)
  const [updatingPrice, setUpdatingPrice] = useState(false)

  const [deletingDeal, setDeletingDeal] = useState(false)
  const [dealDeleted, setDealDeleted] = useState(false)

  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState(null)
  const [modalBody, setModalBody] = useState(null)
  const [confirmText, setConfirmText] = useState(null)
  const [coloredText, segColoredText] = useState(null)
  const [cancelText, setCancelText] = useState(t('common.buttons.cancel'))

  const [totalAmount, setTotalAmount] = useState(0)
  const [dealAddedToLatest, setDealAddedToLatest] = useState(false)
  const [dealWeekIdDefined, setDealWeekIdDefined] = useState(dealWeekId)

  const saveDeal = () => {
    setSavingTrip(true)
    auth.postRequest(LOCATION.TRIPS.API.path, {
      trip_request_id: deal.trip_request?.id,
      deal_id: deal.id,
      location_id: deal.location_id,
      airport_id: deal.airport_id,
      flight_id: deal.flight_id,
      hotel_id: deal.hotel_id,
      total_amount: deal.total_amount,
      currency: deal.currency,
      date: deal.date,
    })
      .then(response => {
        auth.checkToken()
        setTripSaved(true)
        setSavingTrip(false)
      })
      .catch(error => {
        setSavingTrip(false)
      })
  }

  const addToLatestTop15Deals = () => {
    setSavingTrip(true)
    auth.postRequest(`${LOCATION.DEALS_WEEK.API.path}/0/add-deal`, {
      deal_id: deal.id,
      add: 1,
    })
      .then(response => {
        setSavingTrip(false)

        segColoredText(null)
        setModalTitle(t('pages.deals.notifications.deal_added_to_top_15.title'))
        setModalBody(null)
        setConfirmText(t('common.dialogs.actions.okay'))
        setShowModal(true)

        setDealWeekIdDefined(response.data?.data?.id)
        setDealRemoved(false)

      })
      .catch(error => {
        setSavingTrip(false)
      })
  }

  const removeToLatestTop15Deals = () => {
    setSavingTrip(true)
    auth.postRequest(`${LOCATION.DEALS_WEEK.API.path}/${dealWeekIdDefined}/add-deal`, {
      deal_id: deal.id,
      add: 0,
    })
      .then(response => {

        setDealRemoved(true)
        setSavingTrip(false)

        segColoredText(null)
        setModalTitle(t('pages.deals.notifications.deal_removed.title'))
        setModalBody(null)
        setCancelText(null)
        setConfirmText(t('common.dialogs.actions.okay'))
        setShowModal(true)

      })
      .catch(error => {
        setDealRemoved(false)
        setSavingTrip(false)
      })
  }

  const deleteDeal = () => {
    auth.deleteRequest(`${LOCATION.DEALS.API.path}/${deal.id}`)
      .then(response => {
        setDealDeleted(true)
        setDeletingDeal(false)
      })
      .catch(error => {
        setDeletingDeal(false)
      })
  }

  const confirmLivePrice = () => {
    setUpdatingPrice(true)
    auth.getRequest(`${LOCATION.FLIGHTS.API.path}/${deal.flight_id}/live/${deal.id}`)
      .then(response => {
        segColoredText(null)
        if (response.data === 'updated') {
          setModalTitle(t('pages.deals.notifications.live_price_updated.title'))
          setModalBody(t('pages.deals.notifications.live_price_updated.body'))
        } else if (response.data === 'updated_not_direct') {
          setModalTitle(t('pages.deals.notifications.live_price_updated.title'))
          setModalBody(t('pages.deals.notifications.live_price_updated.body'))
          segColoredText(t('pages.deals.notifications.live_price_updated.no_direct_flight_found', { destination: deal.location.destination }))
        } else {
          setModalTitle(t('pages.deals.notifications.live_price_no_found.title'))
          setModalBody(t('pages.deals.notifications.live_price_no_found.body'))
          setUpdatingPrice(false)
        }
        setConfirmText(t('common.dialogs.actions.okay'))
        setCancelText(null)
        setShowModal(true)
      })
      .catch(error => {
        setUpdatingPrice(false)
      })
  }

  const requestRemoveFromDealList = () => {
    setSavingTrip(true)
    segColoredText(null)
    setModalTitle(t('pages.deals.notifications.remove_deal_from_top_15.title'))
    setConfirmText(t('common.dialogs.actions.okay'))
    setShowModal(true)
  }

  const requestDealDelete = () => {
    setDeletingDeal(true)
    segColoredText(null)
    setModalTitle(t('pages.deals.notifications.delete_deal.title'))
    setConfirmText(t('common.dialogs.actions.delete'))
    setShowModal(true)
  }

  const handleConfirm = () => {
    setShowModal(false)
    if (updatingPrice) {
      setUpdatingPrice(false)
      window.location.reload()
    } else if (deletingDeal) {
      deleteDeal()
    } else if (savingTrip) {
      removeToLatestTop15Deals()
    }
  }

  const handleCancel = () => {
    if (updatingPrice) {
      setUpdatingPrice(false)
      window.location.reload()
    }
    setShowModal(false)
    setDeletingDeal(false)
  }

  const checkDealOnLatestList = () => {
    return auth.getRequest(`${LOCATION.DEALS_WEEK.API.path}/0/${deal.id}`)
      .then(response => {
        setDealAddedToLatest(!!response.data?.data)
        setDealWeekIdDefined(response.data?.data?.id)
      })
      .catch(error => {
      })

  }

  useEffect(() => {
    if (auth.user?.admin && !dealWeekIdDefined) {
      let result = checkDealOnLatestList()
      return () => result?.abort && result.abort()
    }
  }, [deal])

  if (dealDeleted) {
    let link = LOCATION.DEALS.LIST.path
    if (dealWeekIdDefined) {
      link = `${LOCATION.DEALS_WEEK.LIST.path}/${dealWeekIdDefined}`
    }
    return (
      <OnPageNotification
        notificationTitle={t('pages.deals.notifications.deal_deleted.title')}
        notificationLinkText={t('pages.deals.buttons.back_to_deals')}
        notificationIcon={<FontAwesomeIcon icon={faCircleCheck}/>}
        notificationLink={`${link}`}
        notificationVariant="primary"
      />
    )
  }

  if (tripSaved) {
    return <OnPageNotification
      notificationTitle={t('pages.deals.notifications.deal_saved.title')}
      notificationDescription={t('pages.deals.notifications.deal_saved.body')}
      // notificationDescriptionSecondLine={t('pages.deals.notifications.deal_saved.second_line_body')}
      notificationDescriptionColored={t('pages.deals.notifications.deal_saved.colored_message')}
      notificationDescriptionColor="black fst-italic"
      notificationLinkText={t('pages.deals.buttons.go_to_my_trips')}
      notificationIcon={<FontAwesomeIcon icon={faCircleCheck}/>}
      notificationLink={LOCATION.TRIPS.MY_SAVED_TRIPS.path}
    />
  }

  return (
    <>
      <AppModal
        show={showModal}
        title={modalTitle}
        body={modalBody}
        confirmText={confirmText}
        cancelText={cancelText}
        coloredText={coloredText}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
      />
      <div className="container p-md-2 p-0">
        {
          auth.user?.client ?
            <Row className="mt-md-4 mt-0 heading-space">
              <Col className="col-md-6 col-12 d-flex">
                <Link to={`${LOCATION.TRIPS.START_YOUR_JOURNEY.path}/results/${deal.trip_request?.id}`}
                      className="btn text-white me-3 py-0 my-0 bg-white text-primary border-0 d-flex align-items-center">
                  <FontAwesomeIcon size="lg" className="text-primary" icon={faArrowLeftLong}/>
                </Link>
                <div className="button-label primary heading-text">
                  {t('pages.deals.trip_overview')}
                </div>
              </Col>
              <Col className="col-md-6 col-12 d-flex justify-content-start justify-content-md-end">
                <div className="button-label light shadow heading-text">
                  {t('common.total_price', {
                    price: deal.total_amount,
                    currency: auth.user?.currency?.symbol
                  })}
                </div>
              </Col>
            </Row>
            :
            <Breadcrumb className="text-dark heading-text my-2">
              <Breadcrumb.Item className="">
                <Link to={LOCATION.DEALS.LIST.path}>{t('pages.deals.title')}</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>{deal.location.destination}</Breadcrumb.Item>
            </Breadcrumb>
        }
        <div className="px-4 p-md-0">
          <div className="d-grid gap-2 border-top-0 bg-white my-4">
            {
              auth.user?.client && !tripExists ?
                <>
                  {
                    deal.flight.live_price && moment().diff(moment(deal?.flight.created_at), 'days') < 1 ?
                      <>
                        <LoadingButton
                          disabled={auth.user?.saved_trip_count !== 0 && auth.user.user_type === 'travel_lite'}
                          loading={savingTrip}
                          className="heading-text text-white"
                          onSubmit={saveDeal}
                          titleTranslationKey={t('pages.deals.buttons.save_deal_to_my_trips')}
                          buttonTextElement={
                          <span className="text-black">
                            Save Deal to my Trips
                          </span>
                        }
                        />
                        <p className="text-center">
                          This allows you to monitor the price and book later
                        </p>
                        {auth.user.user_type === 'travel_lite' ?

                          <p>{t('pages.trips.travel_light_member_trip_saving_statement')}</p>
                          : null
                        }
                      </>
                      : null
                  }

                  {
                    !deal.flight.live_price || moment().diff(moment(deal?.flight.created_at), 'days') >= 1 ?
                      <>
                        <p>{t('pages.deals.indicative_flight_price_explained')}</p>
                      </>
                      : null
                  }
                </>
                :
                !auth.user?.client &&
                <Row>
                  <Col className="col-8 d-grid gap-2">
                    {
                      (dealWeekIdDefined && !dealRemoved) ?
                        <LoadingButton
                          variant="danger"
                          loading={savingTrip}
                          className="heading-text text-white"
                          onSubmit={requestRemoveFromDealList}
                          titleTranslationKey={t('pages.deals.buttons.remove_to_top_15_deals')}
                        />
                        :

                        <LoadingButton
                          loading={savingTrip}
                          className="heading-text text-white"
                          onSubmit={addToLatestTop15Deals}
                          titleTranslationKey={t('pages.deals.buttons.add_to_top_15_deals')}
                        />
                    }
                  </Col>
                  <Col className="col-4 d-grid gap-2">
                    <LoadingButton
                      variant="danger"
                      loading={deletingDeal}
                      className="heading-text text-white"
                      onSubmit={requestDealDelete}
                      titleTranslationKey={t('common.buttons.delete')}
                    />
                  </Col>
                </Row>
            }
          </div>
          <Row className="deals-overview">
            <Col className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 city-card">
              <CityCard
                deal={deal}
              />
            </Col>
            <Col className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4">
              <FlightCard
                flight={deal.flight}
                deal={deal}
                fullCard={false}
                confirmLivePrice={confirmLivePrice}
                updatingPrice={updatingPrice}
              />
            </Col>
            <Col className="col-12  col-sm-12 col-md-6 col-lg-6 col-xl-4">
              <HotelCard
                deal={deal}
                hotel={deal.hotel}
                fullCard={false}
              />
            </Col>
          </Row>
          <Row>
            <p className="my-4 small">{t('pages.deals.affiliate_message')}</p>
          </Row>
        </div>
      </div>
    </>
  )

}

export default TripOverview