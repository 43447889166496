import { Link } from 'react-router-dom'
import { Button, Row, Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import LOCATION from '@/constants/Location'
import LoadingSpinner from './LoadingSpinner'

function OnPageNotification ({
  notificationTitle,
  notificationDescription,
  notificationDescriptionSecondLine,
  notificationLink,
  notificationLinkText,
  notificationIcon,
  notificationAction,
  notificationVariant,
  showProgressSpinner,
  notificationDescriptionColored,
  notificationDescriptionColor,
}) {
  const { t } = useTranslation()

  return (
    <div className="container on-page-notification p-4 d-flex align-items-center justify-content-center">
      <div className="on-page-notification-content">
        <Row className="text-center">
          {
            showProgressSpinner ?
              <div className="w-100 my-4 d-flex justify-content-center align-items-center">
                <LoadingSpinner/>
              </div> :

              <div
                className={`text-${notificationVariant ?? 'primary'}`}>
                {notificationIcon}
              </div>
          }
          <h4 className="my-3 text-primary text-black heading-text pt-3">{notificationTitle}</h4>

          <p className="mb-4" dangerouslySetInnerHTML={{__html: notificationDescription}}/>
          {notificationDescriptionSecondLine && <p className="mb-4">{notificationDescriptionSecondLine}</p>}
          {notificationDescriptionColored &&  <p className={`mb-4 text-${notificationDescriptionColor ?? 'danger'}`}>{notificationDescriptionColored}</p>}

          {
            notificationAction ?
              <Button
                onClick={notificationAction}
                className={`btn btn-${notificationVariant ?? 'dark text-white'} heading-text`}>
                {notificationLinkText}
              </Button>
              :
              <Link
                className={`btn btn-${notificationVariant ?? 'dark text-white'} heading-text`}
                to={notificationLink ?? LOCATION.ROOT.path}
              >
                {notificationLinkText ?? t('common.buttons.back_to_members_area')}
              </Link>
          }
        </Row>
      </div>
    </div>
  )
}

export default OnPageNotification