import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { useTranslation } from 'react-i18next'

function TrialBenefits () {
  const { t } = useTranslation()
  return (
    <>
      <h4 className="my-3 heading-text head">
        {t('common.travel_max_trial.title')}
      </h4>
      <h6 className="my-4 fw-semibold">
        {t('common.travel_max_trial.subtitle')}
      </h6>
      <p className="my-4"> {t('common.travel_max_trial.trial_statement')}</p>
        <ul className="list-group list-group-flush benefits-list">
            <li className="list-group-item d-flex">
                <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>
                Receive personalised deal alerts ensuring you never miss out on the best DIY prices.
            </li>
            <li className="list-group-item d-flex">
                <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>
                Fantastic flight and hotel prices are refreshed every week to keep you ahead of the crowd.
            </li>
            <li className="list-group-item  d-flex">
                <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>
                Control your deal alerts with ease, turning them on or off anytime to fit your travel plans.
            </li>
            <li className="list-group-item d-flex">
                <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>
                Save multiple deals to easily compare options and secure the best one for you.
            </li>
            <li className="list-group-item d-flex">
                <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>
                Stay informed when prices change, so you can book at the perfect time.
            </li>
            <li className="list-group-item d-flex">
                <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>
                Unlock access to incredible trips under £200, designed just for you.
            </li>
            <li className="list-group-item d-flex">
                <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>
                Discover custom weekend getaways, perfect for a spontaneous escape.
            </li>
            <li className="list-group-item d-flex">
                <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>
                Access DIY beach deals in the perfect locations.
            </li>
            <li className="list-group-item d-flex">
                <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>
                Bargain Long-haul adventures make your dream destinations affordable.
            </li>
            <li className="list-group-item d-flex">
                <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>
                Smooth and stress-free journeys with our expert support.
            </li>
            <li className="list-group-item d-flex">
                <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>
                Enjoy access to exclusive discounts and giveaways.
            </li>
        </ul>
    </>
  )
}

export default TrialBenefits