import Slider from 'rc-slider'
import { Form } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import { useAuth } from '@/services/Auth'

import 'rc-slider/assets/index.css';

function BudgetSelection ({ setValue, values, errors, setMaxBudget, currency }) {

  const [maxSelectedBudget, setMaxSelectedBudget] = useState(values?.budget ?? [0, 2500])

  const auth = useAuth()

  const marks = {
    0: currency + '0',
    1250: currency + '1250',
    2500: currency + '2500',
    3750: currency + '3750',
    5000: currency + '5000',
    6250: currency + '6250',
    7500: currency + '7500',
    8750: currency + '8750',
    10000: currency + '10000'
  }
  const maxBudget = 10000
  const minBudget = 0

  useEffect(() => {
    setValue('budget', maxSelectedBudget);
  }, [])

  return (
    <div className="px-2">
      <Slider
        marks={marks}
        range
        min={minBudget}
        max={maxBudget}
        step={50}
        pushable={100}
        onChange={(e) => {
          setValue('budget', e)
          setMaxSelectedBudget(e)
          setMaxBudget(e)
        }}
        defaultValue={maxSelectedBudget}
        allowCross={false}
      />

      <Form.Control.Feedback type="invalid">
        {errors.max_budget && errors.max_budget.message}
      </Form.Control.Feedback>
    </div>
  )
}

export default BudgetSelection