import React, { useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useAuth } from '@/services/Auth'
import { useTranslation } from 'react-i18next'

import Table, { useColumns } from '@/shared/Tables/Users'
import UserFilters from './components/UserFilters'
import AdminPageHeader from '@/layouts/components/AdminPageHeader'
import { Helmet } from 'react-helmet'
import LOCATION from '@/constants/Location'
import { Breadcrumb, Button, Card } from 'react-bootstrap'
import AppModal from '../../../shared/components/AppModal'
import LoadingButton from '../../../shared/components/LoadingButton'
import UsersSummary from "../Dashboard/components/UsersSummary";

function Users () {

  const history = useHistory()
  const route = useLocation()
  const auth = useAuth()
  const { t } = useTranslation()

  const [query, setQuery] = useState(route.state && route.state.query || {
    page: 0,
    skip: 0,
    limit: 15,
    reset: false,
    sort_by: 'expire_first',
    user_type: 'all'
  })


  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState(null)
  const [modalBody, setModalBody] = useState(null)
  const [confirmText, setConfirmText] = useState(null)

  let cancelText = t('common.dialogs.actions.cancel')

  const [searching, setSearching] = useState(false)
  const [requestTagRefreshProcess, setRequestTagRefreshProcess] = useState(false)


  const requestTagRefresh = () => {
    setModalTitle(t('pages.users.notifications.request_tag_refresh.title'))
    setModalBody(t('pages.users.notifications.request_tag_refresh.body'))
    setConfirmText(t('common.dialogs.actions.okay'))
    setShowModal(true)
    setRequestTagRefreshProcess(true)
  }

  const handleConfirm = () => {

    if( requestTagRefreshProcess ){
      refreshCampaignTags()
    }
    setShowModal(false)
    setRequestTagRefreshProcess(false)
  }

  const handleCancel = () => {
    setShowModal(false)
    setRequestTagRefreshProcess(false)
  }

  const refreshCampaignTags = () => {

    auth.getRequest(`${LOCATION.USERS.API.path}/refresh-campaign-tags`)
      .then(response => {
        setModalTitle(t('pages.users.notifications.request_tag_refresh_requested.title'))
        setModalBody(t('pages.users.notifications.request_tag_refresh_requested.body'))
        setConfirmText(t('common.dialogs.actions.okay'))
        setShowModal(true)
      })
      .catch(error => {

      })
  }

  useEffect(() => {
    history.replace(
      route.pathname,
      {
        ...route.state,
        query,
      }
    )
  }, [query])

  return (
    <>
      <AppModal
        show={showModal}
        title={modalTitle}
        body={modalBody}
        confirmText={confirmText}
        cancelText={cancelText}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
      />
      <div className="py-2 px-4">
        <Helmet>
          <title>{t('pages.users.title')} - {t('common.app')}</title>
        </Helmet>
        <div className="my-3 d-flex align-items-center justify-content-between flex-column flex-md-row">
          <Breadcrumb>
            <Breadcrumb.Item active className="heading-text text-dark">{t('pages.users.title')}</Breadcrumb.Item>
          </Breadcrumb>
          <div className="d-grid d-md-flex align-items-center">
            <LoadingButton
              onSubmit={requestTagRefresh}
              variant="dark"
              className="heading-text text-primary me-md-3"
              titleTranslationKey={t('pages.users.buttons.refresh_tags')}
            />
            <Link className="btn btn-primary heading-text my-2 px-5" to={LOCATION.USERS.CREATE.path}>{t('pages.users.buttons.create_admin')}</Link>
          </div>
        </div>
        <UsersSummary/>
        <div className="my-4 bg-white">
          <UserFilters
            searching={searching}
            values={query}
            loadUsers={setQuery}

          />
        </div>
        <div className="my-4 bg-white">
          <Table query={query} setQuery={setQuery} showPagination={true} onData={_ => setSearching(!_)}/>
        </div>
      </div>
    </>
  )

}

export default Users