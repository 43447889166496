const Deals = {
  title: 'Deals',
  title_results: 'Deals - Results',
  top_15: 'Top 15 Deals',
  destination_specific_deals: 'Destination Specific Deals',
  top_deals: 'Top Deals',
  holidays: 'Holidays',
  latest_custom_deals: 'Custom Deals',
  beach_deals: 'Beach Vibes',
  your_deals: 'Your Deals',
  here_what_we_have_found_for_you: 'Here’s what we found for you in {{location}}',
  we_found_deals_on_following_destination: 'We found deals on the following destinations. Choose a destination to see options',
  from_price: 'From  {{price}} ({{price_per_person}}PP)',
  price_with_price_per_person: 'From {{price}} ({{price_per_person}}PP)',
  flights_and_accommodation_for: 'Flights & Accommodation for {{count}}',
  accommodation_for: 'Accommodation for {{count}}',
  flights_for: 'Flights for {{count}}',
  choose_destination: 'Choose Destination',
  choose_deal: 'Choose Deal',
  trip_overview: 'Trip Overview',
  choose_accommodation: 'Choose Accommodation',
  choose_flight: 'Choose Flight',
  accommodation_choices_found: 'Here are the accommodation choices we’ve found in {{city}}',
  flight_choices_found: 'Here are the potential flight choices we’ve found to {{city}}',
  direct_flight: 'Direct Flight',
  top_deals_statement: 'These deals are based on your favourite locations! You can set these in your preferences. ',
  top_deals_update_times: 'These deals were last updated on {{createdAt}}. Note flight and accommodation prices are frequently fluctuating',
  deal_by_budget_statement: 'These fantastic value trips are perfect for a last minute escape. They will be updated weekly!.',
  weekend_trip_statement: 'Weekend trips will show hotels and flights from Thursday/Friday to Sunday/Monday.',
  long_haul_statement: 'All of our long haul destinations are hand picked by us. The hotels we choose are well reviewed and in good locations.',
  beach_deals_statement: 'Beach vibes will show fantastic beach destinations',
  travel_lite_member_statement_for_top_15_deals: 'Did you know? If you sign up to Travel Max you can have custom deals 24/7!',
  travel_max_member_statement_for_top_15_deals: 'Great deals we have found this week! Updated every Sunday. Flight prices fluctuate often, use the "confirm price" button to check the latest price.',
  indicative_flight_price_explained: 'Flight prices are the estimated lowest prices based on searches over the last 4 days',
  affiliate_message: 'Some links on this page are affiliate links and will earn us a small commision if you book through them, this is at no extra cost to yourself - Thank you in advance - Jen',
  types: {
    top_deals: 'Custom Deals',
    deal_by_budget: 'Under £200',
    weekend_trip: 'Weekend Trips',
    long_haul: 'Long Haul',
    beach_deals: 'Beach vibes',
  },
  buttons: {
    view_details_to_here: 'View details to Here',
    view_options_to_here: 'View options to Here',
    view_this_deal: 'View this Deal',
    edit_this_deal: 'Edit this Deal',
    see_more_options: 'See More Options',
    more_information: 'More Information',
    hotel_information: 'Hotel Information',
    flight_information: 'Flights Information',
    book_now: 'Book Now',
    select_deal: 'Select Deal',
    select_hotel: 'Select Hotel',
    select_flights: 'Select Flights',
    save_deal_to_my_trips: 'Save Deal to my Trips',
    conform_price: 'Confirm Price',
    check_live_flight_price: 'Check live flight price',
    choose_hotel_again: 'Other accommodation',
    choose_flights_again: 'View alternative flights',
    mark_hotel_as_booked: 'Mark Hotel as Booked',
    mark_flight_as_booked: 'Mark Flight as Booked',
    go_to_my_trips: 'Go to my Trips',
    back_to_deals: 'Back to Deals',
    book_flights_now: 'Book Flights Now',
    choose_flight_date: 'Choose Flight Date',
    book_accommodation_now: 'Book Accommodation',
    search_for_deals: 'Search for Deals',
    create_deals: 'Create Deals',
    add_to_top_15_deals: 'Add to latest top 15 deals',
    remove_to_top_15_deals: 'Remove from top 15 deals',
    delete_deal: 'Delete',
  },
  notifications: {
    deal_type_not_subscribed: {
      title: "Please turn on {{type}} deals in your preferences to receive weekly deals",
      text: "You can update your preferences with the link below.{{additional}}",
    },
    deals_found: {
      title: 'Your Deals are Ready',
      body: 'We have found deals for you based on your requirements',
      your_trip_summary: 'Your Trip Summary',
    },
    no_deal_found_on_request_limit_reached: {
      title: 'The website is really busy right now!',
      body: 'We currently have a temporary limit on the number of searches that can be done every hour. This will be lifted soon, but in the meantime please check back later on to try your search again! Thank you so much for your patience as we get this site up and running! Jen @TheTravelMum',
    },
    no_deal_found_on_request: {
      title: 'No Deals found',
      body: 'Consider increasing your budget or altering when you can travel',
      reasons: {
        "On Queue": "Request on Queue",
        "Initiated": "Request is initiated",
        "Flights Found": "Flights found",
        "Flights Not Found": "Flights don’t exist for these dates, duration or departing airport. Try a different duration or consider searching from a larger airport. Contact us if you need additional help",
        "Hotels Not Found": "We have not found hotels for the duration and date you specified",
        "No Deals Found Due To Budget": "No deals found within the budget you specified. Consider increasing the budget or reducing the duration. Ensure the budget is for the whole group and not per person",
        "Searching Too Far Ahead": "Searching too far ahead. Budget flights are released around 9 months in advance (Search for an earlier month or come back later)",
        "Flexi Duration Not Found Flights": "Flights were not found for the duration you specified and we have tried +/- 1 day",
      }
    },
    no_deal_found: {
      title: 'No Deals found',
      body: 'Request a trip to receive customized deals for you',
    },
    no_top_deals_found: {
      title: 'No Deals found',
      body: 'We are not able to find deals for you, please adjust your preferences to see the best deals for you here',
    },
    deal_saved: {
      title: 'Deal Saved',
      body: 'We have added this deal to your saved trips so you can come back to it later.',
      second_line_body: 'The next step is to pay for your flights and hotel.',
      colored_message: 'Travel Max members can set price alerts for their saved trips and be alerted once prices change',
    },
    no_top_15_deals_found: {
      title: 'No top 15 Deals found',
      body: 'Our top 15 deals will be updated soon',
    },
    deal_added_to_top_15: {
      title: 'Deal Added',
    },
    delete_deal: {
      title: 'Delete this Deal?',
    },
    deal_deleted: {
      title: 'Deal deleted!',
    },
    deal_removed: {
      title: 'Deal Removed',
    },
    remove_deal_from_top_15: {
      title: 'Remove deal from top 15?',
    },
    live_price_updated: {
      title: 'Prices updated',
      body: 'We have updated the flight prices to the live current price. Flight prices are constantly fluctuating and prices can vary a lot over time. We use data from searches over the last few days to provide the initial price you are shown. This may go up or down once you update to the live price',
      no_direct_flight_found: 'Sorry no direct flights available for {{destination}}, check out these options with stop overs',
    },
    price_refreshed: {
      title: 'Trip Price Refreshed',
      body: 'We have updated the flight and hotel prices to the current price.',
    },
    live_price_no_found: {
      title: 'Price Update Error',
      body: 'Currently, we are unable to retrieve the most recent price. Kindly try again later',
    },
    choose_dates: {
      title: 'Here are date options for you, Please choose your preferred date for each destination so that we can build a deal for you',
    },
    progress: {
      10: {
        title: 'Flights were not found for the duration you specified, we are now trying +1 day',
        description: 'We are now trying +1 day to find flights for you',
      },
      11: {
        title: 'Flights were not found for the duration you specified, we are now trying -1 day',
        description: 'We are now trying -1 day to find flights for you',
      },
      13: {
        title: 'Flights were not found for the airport you specified, we are now trying different airports nearby',
        description: 'We are now trying {{nearest_airports}} to find flights for you',
      }
    }
  },
  form: {},
  deals_week: {},
  stats: {
    labels: {
      deals_created: 'Deals Found',
      number_of_users: 'Total Users',
      users_who_can_receive_emails: 'Users who can receive emails',
      total_emails_sent: 'Total Emails sent',
      last_run_date: 'Last run on',
      users_received_deals: 'Users received deals',
    }
  }
}

export default Deals